import { css } from "@emotion/react"
import { IoIosArrowDroprightCircle } from "react-icons/io"
import { color as csxColor } from "csx"

import { color, hover } from "../../utils/styles"

type Props = {
  className?: string
}

const ReserveButton = ({ className }: Props) => {
  return (
    <div>
      <a
        className={className}
        href="https://yokowake.booking.chillnn.com/"
        target="_blank"
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${color.yellow};
          height: 75px;
          //min-width: 295px;
          max-width: 500px;
          color: ${color.blue};
          font-size: 2.0rem;
          font-weight: bold;
          text-decoration: none;
          border-radius: 8px;
          box-shadow: 2px 2px 0px ${csxColor(color.blue).fade(0.52).toString()};
          transition: opacity 0.4s;
          ${hover(css`
                  &:hover {
                    opacity: 0.8;
                    transform: translate(1px,1px);
                    box-shadow: 1px 1px 0px ${csxColor(color.blue).fade(0.52).toString()};
                  }
                  `)}
                  `}
      >
        <span>ご予約・空室検索</span>
        <IoIosArrowDroprightCircle
          css={css`
            width: 30px;
            height: 30px;
            margin-left: 13px;
            `}
        />
      </a>
      <p
        css={css`
          margin-top: 8px;
          text-align: center;
          color: ${color.blue};
          font-weight: bold;
          font-size: 1.4rem;
          `}
      >
        ※当日のご予約はお電話で受け付けております。
      </p>
    </div>
  )
}
export default ReserveButton
